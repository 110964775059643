import React from "react";
import { injectIntl, FormattedMessage, Link } from "gatsby-plugin-react-intl";
import { useStaticQuery, graphql } from "gatsby";
import { FaPhone, FaFax, FaEnvelope, FaHome, FaYoutube } from "react-icons/fa";
import myLogo from "../../images/footer/logoIG.png";
import footerLogo from "../../images/footer/footerLogo.png";

const Footer = ({ intl }) => {
	const data = useStaticQuery(graphql`
		query {
			allNavbarLinksJson {
				edges {
					node {
						path
						text
					}
				}
			}
			allMachineSelectorDataJson {
				edges {
					node {
						path
						text
					}
				}
			}
		}
	`);
	return (
		<>
			<div className="footer">
				<div className="boxFooterLogo">
					<Link to="/">
						<img
							className="footerLogoImage"
							src={footerLogo}
							alt="footerLogo"
						/>
					</Link>
				</div>

				<div className="boxFooterMiddle">
					<div className="footerMiddleSections">
						<p>{intl.formatMessage({ id: "navbar.machine" })}</p>
						<hr className="footerHr" />
						{data.allMachineSelectorDataJson.edges.map((item, index) => {
							return (
								<div className="footerIconSections" key={index}>
									<div className="machineSmallScreen">
										<Link to={item.node.path}>
											<FormattedMessage id={item.node.text} />
										</Link>
										
									</div>
								</div>
							);
						})}
						<Link to="/machines/processLines">
						<FormattedMessage id="machineSelection.processLines" />
										</Link>
					</div>

					<div className="footerMiddleSections">
						<p>{intl.formatMessage({ id: "footer.address" })}</p>
						<hr className="footerHr" />
						<div className="footerIconSections">
							<div className="footerIcons">
								<FaHome size={20} />
							</div>
							<div className="footerIconText">
								<a
									href="https://www.google.com/maps/place/TEHNOMATIK/@44.0032001,20.5550507,8.06z/data=!4m5!3m4!1s0x0:0xd1d0c2c08bb4146f!8m2!3d43.6564001!4d20.8925622?hl=en"
									target="_blank"
									rel="noopener noreferrer"
								>
									Sinđelićeva 19, 36216 Novo Selo, Vrnjačka Banja, Srbija
								</a>
							</div>
						</div>
					</div>

					<div className="footerMiddleSections">
						<p>{intl.formatMessage({ id: "footer.contact" })}</p>
						<hr className="footerHr" />
						<div className="footerIconSections">
							<div className="footerIcons">
								<FaPhone size={20} />
							</div>
							<div className="footerIconText">
								<a href="tel:+38136631500"> +381 36 631 500</a>
							</div>
						</div>

						<div className="footerIconSections">
							<div className="footerIcons">
								<FaFax size={20} />
							</div>
							<div className="footerIconText">
								<a href="tel:+38136631221"> +381 36 631 221</a>
							</div>
						</div>

						<div className="footerIconSections">
							<div className="footerIcons">
								<FaEnvelope size={20} />
							</div>
							<ul className="footerIconText">
								{/* <li className="footerMails"><a href="mailto:tehnomatik@mts.rs">tehnomatik@mts.rs</a></li> */}
								<li className="footerMails"><a href="mailto:office@tehnomatik.com">office@tehnomatik.com</a></li>
							</ul>
						</div>
					</div>

					<div className="footerMiddleSections">
						<p>{intl.formatMessage({ id: "footer.follow" })}</p>
						<hr className="footerHr" />
						<div className="footerIconSections">
							<div className="footerIcons">
								<FaYoutube size={20} />
							</div>
							<div className="footerIconText">
								<a
									href="https://www.youtube.com/channel/UCd0G9LipKkMQVaN3JsVFkCg"
									target="_blank"
									rel="noopener noreferrer"
								>
									{intl.formatMessage({ id: "footer.youTube" })}
								</a>
							</div>
						</div>
					</div>
				</div>
				
				<ul className="boxFooterBottomNav">
						{data.allNavbarLinksJson.edges.map((item, index) => {
							return (
								<li key={index} className="footerBottomNavText">
									<Link to={item.node.path}>
										{intl.formatMessage({ id: item.node.text })}
									</Link>
								</li>
							);
						})}
					
				</ul>
	
			</div>

			<div className="boxFooterDevelop">
				<a href="mailto:ivangochanin@gmail.com">
					<p>Developed by &copy;</p>
					<img className="myLogoImage" src={myLogo} alt="myLogo" />
					<span className="myLogoText">creW</span>
				</a>
			</div>
		</>
	);
};

export default injectIntl(Footer);
