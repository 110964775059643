import React from "react";
import { HelmetProvider } from "react-helmet-async";
import PropTypes from "prop-types";
import { injectIntl } from "gatsby-plugin-react-intl";
import Navbar from "./Navbar";
import Footer from "./Footer";
import Transition from "../pageTransition/Transition";

const Layout = ({ children, location }) => {
	return (
		<HelmetProvider>
			<div className="mainContainer">
				<div className="layoutNavBig"><Navbar /></div>
				<Transition location={location}>
				<div className="layoutNavSmall"><Navbar /></div>
					<div>
						<main>{children}</main>
					</div>
					<Footer />
				</Transition>
			</div>
		</HelmetProvider>
	);
};

Layout.propTypes = {
	children: PropTypes.node.isRequired
};

export default injectIntl(Layout);
