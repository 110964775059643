import React, {useState, useEffect} from "react";
import Headroom from "react-headroom";
import { injectIntl, Link } from "gatsby-plugin-react-intl";
import { useStaticQuery, graphql } from "gatsby";
import Language from "../languageSelector/Language";
import logo from "../../images/navbar/logo.png";


const Navbar = ({ intl }) => {
	const data = useStaticQuery(graphql`
		query {
			allNavbarLinksJson {
				edges {
					node {
						path
						text
					}
				}
			}
		}
	`);

	const activeStyles = {
		fontWeight: "900",
		textDecoration: "underline dotted #7c9195",
		color: "#335C60",
		letterSpacing: "2px"
	};

	const [isTrue, navbarSwitch] = useState();
	const toggleNav = () => {
		navbarSwitch((isTrue) => !isTrue);
	};

	useEffect(() => {
        let globalDocument = document || {};
        let documentStyle = globalDocument.getElementsByTagName("html")[0];
        isTrue
            ? (documentStyle.style.overflow = "hidden")
            : (documentStyle.style = "");

    }, [isTrue]);

	return (
		<Headroom className="headroomWrap">
			<nav className={
							!isTrue
								? "navbar"
								: "navbarFixed"
						}>
				<div className="navItems">
					<div className="navItemsSmall">
						<Link to="/"><img className="navbarLogo" src={logo} alt="tehnomatik logo"/></Link>

						<button onClick={toggleNav} type="button"
							className={
								isTrue
									? "hamburger hamburgerCollapse isActive"
									: "hamburger hamburgerCollapse"
							}
						>
							<span className="hamburgerBox">
								<span className="hamburgerInner"></span>
							</span>
						</button>
					</div>
					<ul className={
							isTrue
								? "nav-links show-nav" 
								: "nav-links" 
						}
					>
						<div className="languageSelectorSmall">
							<Language />
						</div>
						{data.allNavbarLinksJson.edges.map((item, index) => {
							return (
								<li key={index}>
									<Link to={item.node.path} activeStyle={activeStyles}>{intl.formatMessage({ id: item.node.text })}</Link>
								</li>
							);
						})}
						<div className="navFooterSmall"><p>{intl.formatMessage({ id: "about.subTitle" })}</p></div>
					</ul>



					<div className="languageSelector">
						<Language />
					</div>




				</div>
			</nav>
		</Headroom>
	);
};

export default injectIntl(Navbar);
