// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-machines-horizontal-machines-js": () => import("./../../../src/pages/machines/horizontalMachines.js" /* webpackChunkName: "component---src-pages-machines-horizontal-machines-js" */),
  "component---src-pages-machines-js": () => import("./../../../src/pages/machines.js" /* webpackChunkName: "component---src-pages-machines-js" */),
  "component---src-pages-machines-process-lines-js": () => import("./../../../src/pages/machines/processLines.js" /* webpackChunkName: "component---src-pages-machines-process-lines-js" */),
  "component---src-pages-machines-transporters-js": () => import("./../../../src/pages/machines/transporters.js" /* webpackChunkName: "component---src-pages-machines-transporters-js" */),
  "component---src-pages-machines-vertical-machines-js": () => import("./../../../src/pages/machines/verticalMachines.js" /* webpackChunkName: "component---src-pages-machines-vertical-machines-js" */),
  "component---src-pages-machines-weighing-devices-js": () => import("./../../../src/pages/machines/weighingDevices.js" /* webpackChunkName: "component---src-pages-machines-weighing-devices-js" */),
  "component---src-pages-news-js": () => import("./../../../src/pages/news.js" /* webpackChunkName: "component---src-pages-news-js" */),
  "component---src-pages-packaging-js": () => import("./../../../src/pages/packaging.js" /* webpackChunkName: "component---src-pages-packaging-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */),
  "component---src-templates-all-horizontal-machines-js": () => import("./../../../src/templates/allHorizontalMachines.js" /* webpackChunkName: "component---src-templates-all-horizontal-machines-js" */),
  "component---src-templates-all-posts-js": () => import("./../../../src/templates/allPosts.js" /* webpackChunkName: "component---src-templates-all-posts-js" */),
  "component---src-templates-all-process-line-js": () => import("./../../../src/templates/allProcessLine.js" /* webpackChunkName: "component---src-templates-all-process-line-js" */),
  "component---src-templates-all-transporters-js": () => import("./../../../src/templates/allTransporters.js" /* webpackChunkName: "component---src-templates-all-transporters-js" */),
  "component---src-templates-all-vertical-machines-js": () => import("./../../../src/templates/allVerticalMachines.js" /* webpackChunkName: "component---src-templates-all-vertical-machines-js" */),
  "component---src-templates-all-weighing-devices-js": () => import("./../../../src/templates/allWeighingDevices.js" /* webpackChunkName: "component---src-templates-all-weighing-devices-js" */)
}

