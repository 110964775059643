import React from "react"
import { IntlContextConsumer, changeLocale } from "gatsby-plugin-react-intl"

const languageName = {
  sr: "sr",
  en: "en"
}

const Language = () => {
  return (
    <div>
      <IntlContextConsumer>
        {({ languages, language: currentLocale }) =>
          languages.map(language => (
            <div
              key={language}
              onClick={() => changeLocale(language)}
              onKeyPress={() => changeLocale(language)}
              style={{
                color: currentLocale === language ? `#f5f090` : `#FFF`,
                fontFamily: `Oxanium`,
                fontSize: 14,
                margin: 5,
                padding: 1,
                cursor: `pointer`,
                display: `inline-block`,
                borderRadius: 50,
                background: `#44464A`,
                width: 35,
                height: 35,
                textAlign: `center`,
                paddingTop: 7.5
              }}
            >
              { `/` + languageName[language]}
            </div>
          ))
        }
      </IntlContextConsumer>
    </div>
  )
}

export default Language